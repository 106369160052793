<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.project.publications.name') }}
        </h3>
        <hr>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.project.publications.nameLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
            id="name"
            v-model="publicationData.name"
            debounce="500"
            @input="$emit('updatePublicationSettings', 'name', $event)"
        ></b-form-input>
        <b-form-text>
          {{ $t('settings.project.publications.nameDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.project.publications.descriptionLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-textarea
          id="description"
          v-model="publicationData.description"
          rows="3"
          max-rows="6"
          debounce="500"
          @input="$emit('updatePublicationSettings', 'description', $event)"
        ></b-form-textarea>
        <b-form-text>
          {{ $t('settings.project.publications.descriptionDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <!-- <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.project.publications.seoTitleLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
            id="seo-title"
            v-model="publicationData.og_title"
            debounce="500"
            @input="$emit('updatePublicationSettings', 'og_title', $event)"
        ></b-form-input>
        <b-form-text>
          {{ $t('settings.project.publications.seoTitleDesc') }}
        </b-form-text>
      </b-col>
    </b-row> -->
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.project.publications.permalinkLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
            id="permalink"
            :class="(isInputInvalid) ? 'is-invalid' : ''"
            v-model="publicationData.slug"
            debounce="500"
            @update="setSlug"
        ></b-form-input>
        <div
          v-if="isInputInvalid"
          class="invalid-feedback"
        >
          <span v-if="!$v.publicationData.slug.disallowIndex">
            {{ $t('settings.disallowIndexForSlug') }}
          </span>
          <span v-if="!$v.publicationData.slug.duplicateSlug">
             {{ $t('settings.slugDuplicate') }}
          </span>
          <span v-if="!$v.publicationData.slug.required">
            {{ $t('settings.notEmpty') }}
          </span>
        </div>
        <b-form-text
            v-html="$t('settings.project.publications.permalinkDesc')"
        ></b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>{{ $t('settings.project.publications.coverImageLabel') }}</label>
      </b-col>
      <b-col cols="12" sm="7">
        <image-upload
          :image-object="coverImageObject"
          @upload-image-object="updateUploadImageObject"
        />
        <b-form-text
          v-html="$t('settings.project.publications.coverImageDesc')"
        ></b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.page.pageDescription.tagsLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <v-selectize
          :options="options"
          v-model="selected"
          multiple
          @input="inputTags"
        />
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
          id="is-shared-template"
          size="lg"
          v-model="publicationData.is_shared_template"
          name="is_shared_template"
          @change="$emit('updatePublicationSettings', 'is_shared_template', $event)"
        >
          {{ $t('settings.project.publications.isSharedProjectLabel') }}
        </b-form-checkbox>
        <b-form-text>
          {{ $t('settings.project.publications.isSharedProjectDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
            id="is-visible-on-overview"
            name="isVisibleOnOverview"
            size="lg"
            v-model="publicationData.listed"
            :value="false"
            :unchecked-value="true"
            @change="$emit('updatePublicationSettings', 'listed', $event)"
        >
          {{ $t('settings.project.publications.visibleOnOverviewLabel') }}
        </b-form-checkbox>
        <b-form-text>
          {{ $t('settings.project.publications.visibleOnOverviewDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
            id="hide-from-search-engine"
            name="hideFromSearchEngine"
            size="lg"
            v-model="publicationData.searchable"
            :value="false"
            :unchecked-value="true"
            @change="$emit('updatePublicationSettings', 'searchable', $event)"
        >
          {{ $t('settings.project.publications.hideFromSearchEngineLabel') }}
        </b-form-checkbox>
        <b-form-text>
          {{ $t('settings.project.publications.hideFromSearchEngineDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4 pb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>{{ $t('settings.project.publications.hreflangLabel') }}</label>
      </b-col>
      <b-col cols="12" sm="7" class="mb-4">
        <b-form-textarea
            id="hreflang"
            v-model="publicationData.hreflang"
            rows="3"
            max-rows="6"
            debounce="500"
            @input="$emit('updatePublicationSettings', 'hreflang', $event)"
        ></b-form-textarea>
        <b-form-text
            v-html="$t('settings.project.publications.hreflangDesc')"
        ></b-form-text>
      </b-col>
    </b-row>
    <item-language
      :language-id="publicationData.language_id"
      @language-changed="$emit('updatePublicationSettings', 'language', $event)"
    />
  </div>
</template>

<script>
import 'selectize/dist/css/selectize.default.css';

import SetButtonDisabledInCache from '@/graphQlQueries/mutations/setButtonDisabledInCache';
import slugify from '@sindresorhus/slugify';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

const duplicateSiblingsSlugValidator = (slugsOfAllSiblings) => (value) => {
  const slugifiedValue = slugify(value.trim());
  return !slugsOfAllSiblings.includes(slugifiedValue);
};

const disallowIndex = (value) => slugify(value.trim()) !== 'index';

const checkForSlugify = (value) => {
  const slugifiedValue = slugify(value.trim());
  return slugifiedValue === value.trim();
};

export default {
  name: 'ProjectSettingsPublications',
  components: {
    VSelectize: () => import('@isneezy/vue-selectize'),
    ImageUpload: () => import('@/components/helper/ImageUpload.vue'),
    ItemLanguage: () => import('@/components/settings/ItemLanguage.vue'),
  },
  mixins: [validationMixin],
  props: ['publicationDataProp', 'siblingSlugs', 'projectTags', 'allTags', 'isSysAdmin'],
  data: () => ({
    selected: '',
    options: [],
    coverImageObject: null,
  }),
  validations() {
    return {
      publicationData: {
        slug: {
          required,
          checkForSlugify,
          disallowIndex,
          duplicateSlug: duplicateSiblingsSlugValidator(this.siblingSlugs),
        },
      },
    };
  },
  created() {
    if (this.publicationDataProp) {
      this.publicationData = this.publicationDataProp;
      if (this.publicationData.thumbnails?.cover_image?.url) {
        if (!this.$checkForCloudImage(this.publicationData.thumbnails.cover_image.url)) {
          const imageUrl = this.$makeCloudImage(
            this.publicationData.thumbnails.cover_image.url,
          );
          this.coverImageObject = {
            ...this.publicationData.thumbnails.cover_image,
            url: imageUrl,
          };
        } else {
          this.coverImageObject = { ...this.publicationData.thumbnails.cover_image };
        }
      }
    }
    this.selected = this.projectTags;
    this.options = this.allTags;
    // initially set the disable state of the button on false
    this.$apollo.mutate({
      mutation: SetButtonDisabledInCache,
      variables: {
        isButtonDisabled: false,
      },
    });
  },
  methods: {
    updateUploadImageObject(imageObject) {
      if (imageObject?.url && !this.$checkForCloudImage(imageObject.url)) {
        // eslint-disable-next-line no-param-reassign
        imageObject.url = this.$makeCloudImage(
          imageObject.url,
        );
      }
      this.$emit('updatePublicationSettings', 'coverImage', imageObject);
    },
    inputTags() {
      // eliminate duplicates
      this.selected = [...new Set(this.selected)];
      this.$emit('updatePublicationSettings', 'tags', this.selected);
    },
    async setSlug(value) {
      this.$v.publicationData.slug.$touch();
      if (!this.$v.publicationData.slug.checkForSlugify) {
        this.publicationData.slug = slugify(value.trim());
      }
      if (this.isInputInvalid) {
        // disable the save button
        await this.$apollo.mutate({
          mutation: SetButtonDisabledInCache,
          variables: {
            isButtonDisabled: true,
          },
        });
      } else {
        await this.$apollo.mutate({
          mutation: SetButtonDisabledInCache,
          variables: {
            isButtonDisabled: false,
          },
        });
      }
      this.$emit('updatePublicationSettings', 'slug', slugify(value.trim()));
    },
  },
  computed: {
    isInputInvalid() {
      if (this.$v.publicationData.slug.$anyDirty) {
        if (
          !this.$v.publicationData.slug.duplicateSlug
          || !this.$v.publicationData.slug.disallowIndex
          || !this.$v.publicationData.slug.checkForSlugify
          || !this.$v.publicationData.slug.required
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .selectize-input {
  background: #5b6064;
  border-color: #303337;
}
</style>
