var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(
                " " + _vm._s(_vm.$t("settings.project.publications.name")) + " "
              )
            ]),
            _c("hr")
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.project.publications.nameLabel")) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-input", {
                attrs: { id: "name", debounce: "500" },
                on: {
                  input: function($event) {
                    return _vm.$emit(
                      "updatePublicationSettings",
                      "name",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.publicationData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.publicationData, "name", $$v)
                  },
                  expression: "publicationData.name"
                }
              }),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("settings.project.publications.nameDesc")) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.project.publications.descriptionLabel")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "description",
                  rows: "3",
                  "max-rows": "6",
                  debounce: "500"
                },
                on: {
                  input: function($event) {
                    return _vm.$emit(
                      "updatePublicationSettings",
                      "description",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.publicationData.description,
                  callback: function($$v) {
                    _vm.$set(_vm.publicationData, "description", $$v)
                  },
                  expression: "publicationData.description"
                }
              }),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.project.publications.descriptionDesc")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.project.publications.permalinkLabel")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-input", {
                class: _vm.isInputInvalid ? "is-invalid" : "",
                attrs: { id: "permalink", debounce: "500" },
                on: { update: _vm.setSlug },
                model: {
                  value: _vm.publicationData.slug,
                  callback: function($$v) {
                    _vm.$set(_vm.publicationData, "slug", $$v)
                  },
                  expression: "publicationData.slug"
                }
              }),
              _vm.isInputInvalid
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    !_vm.$v.publicationData.slug.disallowIndex
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("settings.disallowIndexForSlug")) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    !_vm.$v.publicationData.slug.duplicateSlug
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("settings.slugDuplicate")) + " "
                          )
                        ])
                      : _vm._e(),
                    !_vm.$v.publicationData.slug.required
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("settings.notEmpty")) + " "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _c("b-form-text", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("settings.project.publications.permalinkDesc")
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                _vm._s(_vm.$t("settings.project.publications.coverImageLabel"))
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("image-upload", {
                attrs: { "image-object": _vm.coverImageObject },
                on: { "upload-image-object": _vm.updateUploadImageObject }
              }),
              _c("b-form-text", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("settings.project.publications.coverImageDesc")
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.page.pageDescription.tagsLabel")) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("v-selectize", {
                attrs: { options: _vm.options, multiple: "" },
                on: { input: _vm.inputTags },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "is-shared-template",
                    size: "lg",
                    name: "is_shared_template"
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit(
                        "updatePublicationSettings",
                        "is_shared_template",
                        $event
                      )
                    }
                  },
                  model: {
                    value: _vm.publicationData.is_shared_template,
                    callback: function($$v) {
                      _vm.$set(_vm.publicationData, "is_shared_template", $$v)
                    },
                    expression: "publicationData.is_shared_template"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "settings.project.publications.isSharedProjectLabel"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "settings.project.publications.isSharedProjectDesc"
                      )
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "is-visible-on-overview",
                    name: "isVisibleOnOverview",
                    size: "lg",
                    value: false,
                    "unchecked-value": true
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit(
                        "updatePublicationSettings",
                        "listed",
                        $event
                      )
                    }
                  },
                  model: {
                    value: _vm.publicationData.listed,
                    callback: function($$v) {
                      _vm.$set(_vm.publicationData, "listed", $$v)
                    },
                    expression: "publicationData.listed"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "settings.project.publications.visibleOnOverviewLabel"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "settings.project.publications.visibleOnOverviewDesc"
                      )
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "hide-from-search-engine",
                    name: "hideFromSearchEngine",
                    size: "lg",
                    value: false,
                    "unchecked-value": true
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit(
                        "updatePublicationSettings",
                        "searchable",
                        $event
                      )
                    }
                  },
                  model: {
                    value: _vm.publicationData.searchable,
                    callback: function($$v) {
                      _vm.$set(_vm.publicationData, "searchable", $$v)
                    },
                    expression: "publicationData.searchable"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "settings.project.publications.hideFromSearchEngineLabel"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "settings.project.publications.hideFromSearchEngineDesc"
                      )
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4 pb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                _vm._s(_vm.$t("settings.project.publications.hreflangLabel"))
              )
            ])
          ]),
          _c(
            "b-col",
            { staticClass: "mb-4", attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "hreflang",
                  rows: "3",
                  "max-rows": "6",
                  debounce: "500"
                },
                on: {
                  input: function($event) {
                    return _vm.$emit(
                      "updatePublicationSettings",
                      "hreflang",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.publicationData.hreflang,
                  callback: function($$v) {
                    _vm.$set(_vm.publicationData, "hreflang", $$v)
                  },
                  expression: "publicationData.hreflang"
                }
              }),
              _c("b-form-text", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("settings.project.publications.hreflangDesc")
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("item-language", {
        attrs: { "language-id": _vm.publicationData.language_id },
        on: {
          "language-changed": function($event) {
            return _vm.$emit("updatePublicationSettings", "language", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }